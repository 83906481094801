import Header from "../../components/Header";
import Home from "./Home";
import SectionTwoMob from "./SectionTwoMob";
import ChooseYourLegends from "./ChooseYourLegends";
import WinAndLevelUp from "./WinAndLevelUp";
import UnlockPower from "./UnlockPower";
import Discover from "./Discover";
import StepCenter from "./StepCenter";
import Footer from "../../components/Footer";

import "./index.sass";

interface IMobile {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Mobile = ({ handlePlay, handlePlayAsAGuest }: IMobile) => {
  return (
    <>
      <Header />
      <Home handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
      <SectionTwoMob />
      <ChooseYourLegends handlePlay={handlePlay} />
      <WinAndLevelUp />
      <UnlockPower />
      <Discover />
      <StepCenter handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
      <Footer />
    </>
  );
};

export default Mobile;
