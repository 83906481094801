import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import UnityGame from "./UnityGame";
import Pages from "./pages";

import LoadingPage from "./pages/LoadingPage";
import TelemetryHelper from "./utils/TelemetryHelper";

const appCognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN || "";
const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID || "";
const logout_uri = process.env.REACT_APP_COGNITO_LOGOUT_URI || "";

const App = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [unity, setUnity] = useState(false);

  const getSearchParameter = (key: string) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    window.location.href = `${appCognitoDomain}/logout?client_id=${client_id}&logout_uri=${encodeURIComponent(
      logout_uri
    )}&response_type=code`;
  };

  useEffect(() => {
    const init = async () => {
      const game = getSearchParameter("game");
      const logoutParam = getSearchParameter("logout");

      if (game === "true") {
        TelemetryHelper.event({
          category: "Homepage",
          action: "Redirection",
          label: "Login Redirect",
        });

        setLoading(true);
        setUnity(true);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }
      if (logoutParam === "true") {
        logout();
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!unity) setLoading(auth.isLoading);
  }, [unity, auth]);

  const handlePlayAsAGuest = () => {
    TelemetryHelper.event({
      category: "Homepage",
      action: "Click",
      label: "Play as Guest",
    });

    alert("Not implemented yet");
  };

  const handlePlay = () => {
    TelemetryHelper.event({
      category: "Homepage",
      action: "Click",
      label: "Play Now",
    });

    setLoading(true);
    auth.signinRedirect();
  };

  // Telemetry tracking init
  useEffect(() => {
    TelemetryHelper.init();
    TelemetryHelper.send({ hitType: "SinglePage", page: "/", title: "App" });
  }, []);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && <>{!unity && <Pages handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />}</>}

      {unity && <UnityGame setLoading={setLoading} />}
    </>
  );
};

export default App;
