import { useEffect, useState } from "react";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface IHomepage {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Homepage = ({ handlePlay, handlePlayAsAGuest }: IHomepage) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {width < 1024 ? (
        <Mobile handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
      ) : (
        <Desktop handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
      )}
    </>
  );
};

export default Homepage;
