import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import "./UnityGame.css";

interface UnityGameProps {
  setLoading: (loading: boolean) => void;
}

const UnityGame = ({ setLoading }: UnityGameProps) => {
  const auth = useAuth();
  const [unityInstance, setUnityInstance] = useState<any | null>(null);

  const IsMobile = () =>
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i);

  const CopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful: " + text);
      },
      function (err) {
        console.error("Async: Could not copy text: " + text, err);
      }
    );
  };

  useEffect(() => {
    const loadUnityScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    const loadUnity = async () => {
      const base = "game";
      const version = "0_1_29";
      try {
        await loadUnityScript(`${base}/Build/${version}.loader.js`);
        // @ts-ignore
        const unityInstance = await createUnityInstance(document.querySelector("#unity-canvas"), {
          dataUrl: `${base}/Build/${version}.data`,
          frameworkUrl: `${base}/Build/${version}.framework.js`,
          codeUrl: `${base}/Build/${version}.wasm`,
          streamingAssetsUrl: `${base}/StreamingAssets`,
          companyName: "AdenInteractive",
          productName: "ITHF - Digital AI Legends",
          productVersion: "0.0.1",
          matchWebGLToCanvasSize: true,
        });

        setUnityInstance(unityInstance);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const GetAccessToken = () => {
      setLoading(false);
      const id_token = auth.user?.id_token;
      return id_token;
    };

    if (auth.isAuthenticated) {
      (window as any).GetAccessToken = GetAccessToken;
      if (unityInstance === null) loadUnity();
    }
  }, [auth, unityInstance]);

  useEffect(() => {
    (window as any).ToFullscreen = () => {
      if (unityInstance) unityInstance.SetFullscreen(1);
    };

    (window as any).ExitFullscreen = () => {
      if (unityInstance) unityInstance.SetFullscreen(0);
    };

    return () => {
      delete (window as any).ToFullscreen;
      delete (window as any).ExitFullscreen;
    };
  }, [unityInstance]);

  useEffect(() => {
    (window as any).IsMobile = IsMobile;
    (window as any).CopyToClipboard = CopyToClipboard;
  }, []);

  return (
    <div id="unity-container" className={`${IsMobile() ? "unity-mobile" : "unity-desktop"}`}>
      <canvas id="unity-canvas" width={1920} height={1080}></canvas>
    </div>
  );
};

export default UnityGame;
